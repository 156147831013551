import Banner from "../components/Main";
import MobileMain from "../components/MobileMain";
import useDeviceDetect from "../useDeviceDetect";
import { useState, useEffect } from "react";



const HomePage = () => {
  let isMobile = useDeviceDetect();
  const [chapter, setChapter] = useState(1);

  return (
    <div>
      {isMobile || window.innerWidth< window.innerHeight ? <MobileMain /> :<Banner/>}
       </div>
  );
};

export default HomePage;

/*
 <TeamContainer />
*/
