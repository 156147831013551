import styled from "styled-components";
import { theme } from "../styles/Global";
import { useState, useEffect } from "react";
import { Spinner } from "../svg_components";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { toast, ToastContainer } from "react-toastify";

const MobileMain = ({ chapter, setChapter }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState('');
  const [points, setPoints] = useState(0);
  const click = new Audio('/sounds/a-4.mp3');

  const api = 'https://fomo.lodosyazilim.com.tr'

  useEffect(() => {
    if (points === 5) {
      const audio = new Audio('/sounds/eagle.mp3');

      audio.play();

      return () => {
        audio.pause();
        audio.currentTime = 0;
      };
    }
  }, [points])

  return (
    <BannerStyled>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        theme="dark"
      />
      {isLoading &&
        <div className="loading">
          <h3>{loadingInfo}</h3>
          <Spinner />
        </div>
      }

      <img className="background" src="/images/background.jpg" alt="" />
      <div className="container">
        <div className="title">
          {points}
        </div>
        <div className="buttons">
          <img draggable={false} className="clash" onClick={() => {
            click.play();
            setPoints(prev => prev + 1)

          }} src="/images/clash_token.png" />

        </div>
      </div>

    </BannerStyled>
  );
};

export default MobileMain;
export const BannerStyled = styled.div`
  position: relative;
  z-index: 2;
  height: 100vh;
  overflow: hidden;
  .background {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    /* width: 100%; */
    height: 100%;
  }
  .container{
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 10;
    .title{
      position: relative;
      font-size: 64px;
      font-weight: 600;
      font-family: regular-grotesk;
      color: #181368;
      top: 110px;
      width: 100%;
      text-align: center;
      .kartal{
        position: absolute;
        left: -120px;
        animation: kartalucuyor 2s ease-in-out forwards;
      }
    }
    .buttons{
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 202px;
      .clash{
        width: 200px; /* Görüntü boyutunu ayarlayabilirsiniz */
        user-select: none;
  height: 200px;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  -webkit-tap-highlight-color: transparent;

  &:active {
    transform: skewY(2deg);
    transform: skewX(2deg);
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 1);
  }
  &:focus {
    outline: none; /* Mavilik çıkmasını engeller */
  }
      }
    }

  }
 
  .loading{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100000;
    height: 100%;
    width: 100%;
    h3{
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%,-50%);
      font-size: 22px;
      color: white;
    }
    svg{
      position: absolute;
      width: 200px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 100000;
      height: 200px;
    }
  }

  @keyframes kartalucuyor {
    0%{
      left: -120px;
    }
    40%{
      left: 30%;
    }
    100%{
      left: 100%;
      display: none;
    }
  }
`;
