import styled from "styled-components";
import { theme } from "../styles/Global";
import { useState, useEffect } from "react";
import { Spinner } from "../svg_components";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { toast, ToastContainer } from "react-toastify";

const Main = ({ chapter, setChapter }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState('');
  const [twitter, setTwitter] = useState(false);
  const [verify, setVerify] = useState(false);
  const [errorVerify, setErrorVerify] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [chapter1Problem, setChapter1Problem] = useState(false);
  const [errorInput, setErrorInput] = useState({id:0,text:''});
  const [walletError, setWalletError] = useState(false);
  const [profileInfo, setProfileInfo] = useState({
    id: 0,
    name: '',
    profile_image_url_https: '',
    wallet: ''
  });
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [calistir, setCalistir] = useState(0);
  const [walletInput, setWalletInput] = useState("");

  const param = useLocation()
  const history = useHistory()
  const api = 'https://fomo.lodosyazilim.com.tr'
  // useEffect(()=>{
  //   setTwitter(true)
  //   setChapter(3)
  // },[])
 

  return <BannerStyled>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      theme="dark"
    />
    {isLoading &&
      <div className="loading">
        <h3>{loadingInfo}</h3>

        <Spinner />
      </div>
    }

    <img className="background" src="/images/background.jpg" alt="" />
    <div className="container">
      <div className="title">
        FOMO SAPIENS
      </div>
      <div className="buttons">
      <div className="top">
          <div>
            get invited
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24">
              <text id="_" data-name="➤" transform="translate(0 20)" font-size="25" font-family="ZapfDingbatsITC, Zapf Dingbats"><tspan x="0" y="0">➤</tspan></text>
            </svg>
          </div>
          <div>
            invite frens<svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24">
              <text id="_" data-name="➤" transform="translate(0 20)" font-size="25" font-family="ZapfDingbatsITC, Zapf Dingbats"><tspan x="0" y="0">➤</tspan></text>
            </svg>
          </div>
          <div>
            submit wallet adress for pre-sale
           
          </div>
        </div>
       
      </div>
    
    </div>

    


  </BannerStyled>
};

export default Main;
export const BannerStyled = styled.div`
  position: relative;
  z-index: 2;
  height: 100vh;
  overflow: hidden;
  .background {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  .container{
    transition: all 0.3s ease-in-out;
    position: relative;
    background-color: rgba(255, 255, 255, 0.9); 
    width: 100%;
    height: 100%;
    z-index: 10;
    .title{
      position: relative;
      font-size: 11vw;
      font-family: regular-grotesk;
      opacity: 1;
      color: black;
      top: 14vh;
      width: 100%;
      text-align: center;
      @media (max-width: 920px) {
        font-size: 10vh;
      }
    }
    .buttons{
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 14vh;
      z-index: 99;
      .top{
        display: flex;
        flex-direction: row;
        div{
          margin-inline: 1vh;
          font-size: 4vh;
          font-family: light-grotesk;
          font-weight: 800;

          svg{
            margin-left: 2vh;
            position: relative;
            top: 0.25vh;
            width: 2.2vh;
            height: 2.2vh;
          }
        }
      }
      .connect{
        background-color: black;
        border-radius: 4vh;
        position: relative;
        cursor: pointer;
        top: 3vh;
        width: auto;
        height: auto;
        z-index: 99;
        padding: 0.8vh;
        padding-inline: 3vh;
        font-family: light-grotesk;
        text-align: center;
        color: white;
        padding-top: 0.8vh;
        font-size: 3vh;
        transition: all 0.3s ease-in-out;
        :hover{
          opacity: 0.7;
        }
        svg{
          position: relative;
          width: 3vh;
          height: 3vh;
          top: 0.5vh;
          left: 1vh;
        }
      }
    }
    .forniture{
      transition: all 0.3s ease-in-out;
      .chair{
        width: auto;
        height: 50vh;
        position: absolute;
        left: 50%;
        bottom: 15px;
        z-index: 2;
        transform: translateX(-50%);
      }
      .path{
        width: 100vw;
        height: 18vh;
        position: absolute;
        left: 50%;
        bottom: 0px;
        z-index: 1;
        transform: translateX(-50%);
      }
    }
  }
  .container-2{
    transition: all 0.3s ease-in-out;
    position: relative;
    margin-inline: 13vw;
    margin-block: 17vh;
    width: 74vw;
    height: 64vh;
    background-color: white;
    border-radius: 5vh;
    z-index: 10;
    .titles-2{
      display: flex;
      justify-content: space-between;
      margin-inline: 3vw;
      position: relative;
      top: 4vh;
      .profile{
        display: flex;
        img{
          border-radius: 50%;
          width: 8vh;
          height: 8vh;
        }
        p{
          font-size: 2.3vh;
          font-family: bold-segoe;
          margin-top: 3vh;
          margin-left: 2vh;
        }
      }
      .sign-out{
       position: relative;
       cursor: pointer;
        font-size: 2.3vh;
        font-family: light-grotesk;
        margin-top: 1vh;
        text-align: center;
        height: 5vh;
        width: 18vh;
        color: white;
        border-radius: 10vh;
        background-color: black;
        transition: all 0.3s ease-in-out;
       display: flex;
       justify-content: center;
       align-items: center;
       p{
        font-weight: 300;
       }
        :hover{
          opacity: 0.7;
        }
      }
    }
    .card-container{
      transition: all 0.3s ease-in-out;
      animation: start 1s ease-in-out;
    .level{
      transition: all 0.3s ease-in-out;
      img{
        transition: all 0.3s ease-in-out;
        position: relative;
        width: 60vw;
        top: 12vh;
        left: 6vw;
      }
    }
    .desc{
        position: relative;
        text-align: center;
        font-family: regular-segoe;
        top: 17vh;
        font-size: 3.5vh;
        span{
          font-weight: 700;
          color: #1f3823;
          position: relative;
        }
        h4{
          font-size: 3.6vh;
          padding-top: 20px;
          font-weight: 400;
        }
        .warning{
          svg{
            width: 30px;
            height: 30px;
            position: relative;
            top: 5px;
            right: 10px;
          }
          width: 100%;
          position: absolute;
          left: 50%;
          font-size: 3vh;
          font-weight: 600;
          top: 7vh;
          color: #DD4F4F;
          transform: translate(-50%);
        }
    }
    .buttons{
        position: relative;
        top: 33vh;
        display: flex;
        justify-content: center;
        
      .next{
        cursor: pointer;
        text-align: center;
        background-color: #7F0024;
        font-size: 2.5vh;
        line-height: 3vh;
        font-family: light-grotesk;
        outline-style: none;
        color: white;
        padding-block: 1.2vh;
        padding-inline: 3.5vh;
        font-weight: 300;
        border-radius: 3vh;
        transition: all 0.3s ease-in-out;
        a{
          color: white;
        }
        :hover{
          opacity: 0.7;
        }
      }
    }
    .inputs{
      position: relative;
        top: 22vh;
        height: 3vh;
        gap: 2vw;
        display: flex;
        justify-content: center;
      flex-direction: row;
      input{
        height: 6vh;
        width: 16vw;
        padding: 2vh;
        border: 0.8px solid #707070;
        color: #707070;
        font-size: 1.98vh;
        border-radius: 10px;
        opacity: 1;
        font-family: regular-segoe;
        ::placeholder {
         opacity: 0.5; 
        }
        :nth-child(4)::placeholder {
         opacity: 0.4; 
         text-align: center;
        }
      }
    }
  }
  .error{
    position: absolute;
    border: 1px solid #707070;
    border-radius: 1vh;
    p{
      font-size: 1.4vh;
      font-family: regular-segoe;
      color: #707070;
      padding: 0.7vh;
    }
    span{
      position: absolute;
      border: 1px solid #707070;
      color: white;
      left: -1vh;
      width: 2vh;
      height: 2vh;
      background-color: #7F0024;
      text-align: center;
      padding-top: 0.2vh;
      font-size: 1.6vh;
      border-radius: 50%;
    }
  }
  .last{
    display: flex;
    flex-direction: row;
    margin-top: 5vh;
    justify-content: center;
    .last_card{
      background-color: #F1F1F1;
      p{
        padding-inline: 1vh;
        padding-block: 0.5vh;
        font-family: regular-segoe;
        font-size: 1.4vh;
      }
      p:last-child{
        padding-bottom: 2vh;
      }
      img{
        height: 30vh;
        margin-bottom: 1vh;
      }
    }
    .congrats{
      margin-left: 4vw;
      margin-top: 8vh;
      
      h1{
        font-size: 6vh;
        font-family: semibold-grotesk;
        color: #7F0024;
        margin-bottom: -2vh;
      }
      p{
        width: 36vh;
        font-family: regular-segoe;
        font-size: 2vh;
        line-height: 3vh;
        margin-top: 2vh;
      }
      input{
        background-color: #F1F1F1;
        border: 0px;
        border-radius: 1px;
        height: 4vh;
        padding: 1vh;
        margin-top: 2vh;
        font-size: 2vh;
      }
      svg{
        margin-left: 0.4vw;
        transition: all 0.3s ease-in-out;
        position: relative;
        top: 3vh;
        width: 2vh;
        height: 2vh;
        cursor: pointer;
        :hover{
          opacity: 0.7;
        }      }
      .edit{
        background-color: #7F0024;
        color: white;
        border-radius: 3vh;
        height: 3.2vh;
        font-size: 2vh;
        font-family: regular-segoe;
        width: 8vw;
        min-width: 14vh;
        text-align: center;
        padding-top: 0.5vh;
        margin-top: 2vh;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        :hover{
          opacity: 0.7;
        }
      }
    }
  }
  }

  @keyframes start {
    from{
      opacity: 0;
    }to{
      opacity: 1;
    }
  }
  .loading{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100000;
    height: 100%;
    width: 100%;
    h3{
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%,-50%);
      font-size: 22px;
      color: white;
    }
    svg{
      position: absolute;
      width: 200px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 100000;
      height: 200px;
    }
  }
`;
